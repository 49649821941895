import Project from 'api/project'
import RecommendedProjects from 'api/recommended-projects'
import range from 'lodash/range'
import sortBy from 'lodash/sortBy'

import constants from '../constants/projects'
import Query from '../lib/prepare-query'
import { PROJECTS_FOR_AE } from '../lib/constants/global'

const THRESHOLD_PROJECT_COUNT = 50

export const queryProjects = (query) => async (dispatch) => {
  dispatch({
    type: constants.FETCH_PROJECTS_REQUEST
  })

  try {
    const response = await Project.query(query)
    dispatch({
      type: constants.FETCH_PROJECTS_SUCCESS,
      projects: response.map((p) => {
        return {
          id: p.id,
          ...p.attributes()
        }
      }),
      meta: response.meta
    })
  } catch (response) {
    dispatch({
      type: constants.FETCH_PROJECTS_FAILURE,
      errorMessage: response.statusText || response.status
    })
  }
}

export const fetchAllProjects =
  (fields = [], allProjects = false) =>
    async (dispatch) => {
      dispatch({
        type: constants.FETCH_ALL_PROJECTS_REQUEST
      })

      try {
        const errors = []

        const query = {
          page: 1,
          per_page: allProjects ? 999 : THRESHOLD_PROJECT_COUNT, // dirty code, needs to be removed. Proper condition to be handled in BE
          sort: 'name',
          order: 'asc',
          assigned: true,
          filterable: true,
          fields: {
            project: ['name', ...fields]
          }
        }

        const result = await Project.query(query)

        const response = {
          data: [...result],
          meta: result.meta
        }

        if (result && result.meta && result.meta.all > THRESHOLD_PROJECT_COUNT) {
          const totalPages = Math.ceil(result.meta.all / THRESHOLD_PROJECT_COUNT)
          // Ex. range(2, 5) -> [2, 3, 4] // 50/page 151/total
          const pages = range(2, totalPages + 1)

          await Promise.all(
            pages.map(async (page, index) => {
              const _query = { ...query, page }
              const result = await Project.query(_query).catch((e) => ({
                chunkIndex: index,
                error: new Error(e)
              }))
              response.data = response.data.concat(result)
            })
          )
        }

        const projects = []

        response.data.forEach((res) => {
          res && res.error instanceof Error
            ? errors.push(res)
            : projects.push({ id: res.id, ...res.attributes() })
        })

        dispatch({
          type: constants.FETCH_ALL_PROJECTS_SUCCESS,
          projects: sortBy(projects, 'name'),
          meta: response.meta
        })
      } catch (response) {
        dispatch({
          type: constants.FETCH_ALL_PROJECTS_FAILURE,
          errorMessage: response.statusText || response.status
        })
      }
    }

const projectAPICall = async (dispatch, getState, queryObject, projects) => {
  try {
    const response = await Project.query(queryObject)
    const resList = response.map((project) => ({
      id: project.id,
      ...project.attributes()
    }))

    const result = projects.length > 0 ? [...projects, ...resList] : resList
    dispatch({
      type: constants.FETCH_PROJECTS_SUCCESS,
      projects: result,
      meta: response.meta
    })
  } catch (error) {
    dispatch({
      type: constants.FETCH_PROJECTS_FAILURE,
      errorMessage: error.statusText || error.status
    })
  }
}

export const fetchProjects =
  (filterable = true, assigned = false) =>
    async (dispatch, getState) => {
      dispatch({
        type: constants.FETCH_PROJECTS_REQUEST
      })

      const query =
      filterable === false
        ? { ...getState().projects.query, assigned }
        : {
          ...getState().projects.query,
          assigned: true,
          filterable: filterable
        }

      const state = getState().projects.filters.state
      const filters = {
        ...getState().projects.filters,
        state: state === 'all' ? '' : state
      }

      const queryObject = new Query(query, filters)
      await projectAPICall(dispatch, getState, queryObject, [])
    }

export const fetchProjectsForAE = () => async (dispatch, getState) => {
  dispatch({
    type: constants.FETCH_PROJECTS_REQUEST
  })

  const query = {
    ...getState().projects.query,
    fields: {
      project: [
        'name',
        'image_url',
        'banner_url',
        'description',
        'permalink',
        'state',
        'published_at',
        'parent_id',
        'visibility_mode'
      ]
    },
    per_page: 50,
    assigned: true,
    filterable: true
  }

  const filters = {
    ...getState().projects.filters,
    sort: { created_at: 'desc' },
    state: PROJECTS_FOR_AE
  }

  const queryObject = new Query(query, filters)
  await projectAPICall(dispatch, getState, queryObject, getState().projects.projects)
}

export const updateQuery = (query) => {
  return {
    type: constants.UPDATE_PROJECTS_QUERY,
    query
  }
}

export const updateFilters = (filters) => {
  return {
    type: constants.UPDATE_PROJECTS_FILTERS,
    filters
  }
}

export const filterByName = (search) => {
  return {
    type: constants.FILTER_PROJECTS_BY_NAME,
    search
  }
}

export const resetProjectsPage = () => {
  return {
    type: constants.RESET_PROJECTS_PAGE
  }
}

export const toggleProject = (id) => {
  return {
    type: constants.TOGGLE_PROJECT,
    id
  }
}

export const toggleAllProjects = (selected) => {
  return {
    type: constants.TOGGLE_ALL_PROJECTS,
    selected
  }
}

export const fetchRecommendedProjects = () => async (dispatch) => {
  dispatch({
    type: constants.FETCH_RECOMMENDED_PROJECTS_REQUEST
  })

  try {
    const response = await RecommendedProjects.fetchAll()
    dispatch({
      type: constants.FETCH_RECOMMENDED_PROJECTS_SUCCESS,
      recommendations: response
    })
  } catch (error) {
    dispatch({
      type: constants.FETCH_RECOMMENDED_PROJECTS_FAILURE,
      errorMessage: error.statusText || error.status
    })
  }
}
