import find from 'lodash/find'
import constants from 'constants/footer-links'
import NavigationLink from 'api/navigation-link'
import Query from 'lib/prepare-query'

export const fetchFooterLinks = () => async (dispatch, getState) => {
  const { queries, filters } = getState().adminFooterLinks
  const query = new Query(queries, filters)

  dispatch({
    type: constants.FETCH_FOOTER_LINKS_REQUEST
  })

  try {
    const navigationLinks = await NavigationLink.query(query)
    dispatch({
      type: constants.FETCH_FOOTER_LINKS_SUCCESS,
      footerLinks: navigationLinks.map(l => ({ id: l.id, ...l.attributes() }))
    })
  } catch (response) {
    dispatch({
      type: constants.FETCH_FOOTER_LINKS_FAILURE,
      errorMessage: response.statusText || response.status
    })
  }
}

export const fetchFooterLink = id => async (dispatch, getState) => {
  const { footerLinks } = getState().adminFooterLinks

  dispatch({
    type: constants.FETCH_FOOTER_LINK_REQUEST
  })

  const response = find(footerLinks, ['id', id])

  if (response) {
    dispatch({
      type: constants.FETCH_FOOTER_LINK_SUCCESS,
      footerLink: response
    })
  } else {
    dispatch({
      type: constants.FETCH_FOOTER_LINK_FAILURE,
      errorMessage: 'Not Found'
    })
  }
}

export const updateFooterLink = (key, value) => {
  return {
    type: constants.UPDATE_FOOTER_LINK,
    key,
    value
  }
}

export const saveFooterLink = () => async (dispatch, getState) => {
  const { footerLink } = getState().adminFooterLinks

  dispatch({
    type: constants.SAVE_FOOTER_LINK_REQUEST
  })

  const navigationLink = NavigationLink.new(footerLink)

  try {
    await navigationLink.save()
    dispatch({
      type: constants.SAVE_FOOTER_LINK_SUCCESS
    })
  } catch (response) {
    dispatch({
      type: constants.SAVE_FOOTER_LINK_FAILURE,
      errorMessage: response.message || response.statusText || response.status,
      errors: navigationLink.errors.extract()
    })
  }
}

export const deleteFooterLink = id => async dispatch => {
  dispatch({
    type: constants.DELETE_FOOTER_LINK_REQUEST,
    id
  })

  const navigationLink = NavigationLink.new({ id })

  try {
    await navigationLink.destroy()

    dispatch({
      type: constants.DELETE_FOOTER_LINK_SUCCESS,
      id
    })
  } catch (error) {
    dispatch({
      type: constants.DELETE_FOOTER_LINK_FAILURE,
      id,
      errorMessage: error.statusText || error.status
    })
  }
}

export const sortFooterLinks = body => async dispatch => {
  const navigationLink = NavigationLink.new()

  try {
    await navigationLink.sort({
      data: {
        attributes: { navigation: body }
      }
    })

    dispatch({
      type: constants.SORT_FOOTER_LINKS_SUCCESS
    })
  } catch (err) {
    dispatch({
      type: constants.SORT_FOOTER_LINKS_FAILURE,
      errorMessage: err
    })
  }
}

export const resetFooterLink = () => {
  return {
    type: constants.RESET_FOOTER_LINK
  }
}

export const updateFooterLinkError = (key, value) => {
  return {
    type: constants.UPDATE_FOOTER_LINK_ERROR,
    key,
    value
  }
}
