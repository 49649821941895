// More info regarding allowed characters in a file name: https://helpdesk.bangthetable.com/en/articles/1566185-troubleshoot-unable-to-save-the-uploaded-file
export const INVALID_EHQ_FILE_NAME_CHARACTERS = /[#%&{}\\<>*?/$!'":@+\[\]=,\/;\|\^~]/gm;

export const sanitizedFileName = (name) => {
  return name.replace(INVALID_EHQ_FILE_NAME_CHARACTERS, '');
}

export const sanitizedFile = (file) => {
  if (file instanceof File) {
    return new File([file], sanitizedFileName(file.name), { type: file.type });
  }
  return file;
}

export const isFileNameInvalid = (file) => {
  return INVALID_EHQ_FILE_NAME_CHARACTERS.test(file.name)
}
