import find from 'lodash/find'
import constants from 'constants/header-links'
import NavigationLink from 'api/navigation-link'
import Query from 'lib/prepare-query'

export const fetchHeaderLinks = () => async (dispatch, getState) => {
  const { filters, queries } = getState().adminHeaderLinks
  const query = new Query(queries, filters)
  query.per_page = 9999

  dispatch({
    type: constants.FETCH_HEADER_LINKS_REQUEST
  })

  try {
    const navigationLinks = await NavigationLink.query(query)

    dispatch({
      type: constants.FETCH_HEADER_LINKS_SUCCESS,
      headerLinks: navigationLinks.map(l => ({ id: l.id, ...l.attributes() }))
    })
  } catch (response) {
    dispatch({
      type: constants.FETCH_HEADER_LINKS_FAILURE,
      errorMessage: response
    })
  }
}

export const fetchHeaderLink = id => (dispatch, getState) => {
  const { headerLinks } = getState().adminHeaderLinks

  dispatch({
    type: constants.FETCH_HEADER_LINK_REQUEST
  })

  const response = find(headerLinks, ['id', id])

  if (response) {
    dispatch({
      type: constants.FETCH_HEADER_LINK_SUCCESS,
      headerLink: response
    })
  } else {
    dispatch({
      type: constants.FETCH_HEADER_LINK_FAILURE,
      errorMessage: 'Not Found'
    })
  }
}

export const saveHeaderLink = () => async (dispatch, getState) => {
  var headerLink = { ...getState().adminHeaderLinks.headerLink }

  if (headerLink.linkableType === 'Parent') headerLink.linkableType = 'TmpExternalLink'

  const data = new Query(headerLink)

  if (!data.parentId) data.parentId = ''

  dispatch({
    type: constants.SAVE_HEADER_LINK_REQUEST
  })

  const navigationLink = NavigationLink.new(data)

  try {
    await navigationLink.save()

    dispatch({
      type: constants.SAVE_HEADER_LINK_SUCCESS
    })
  } catch (error) {
    dispatch({
      type: constants.SAVE_HEADER_LINK_FAILURE,
      errorMessage: error.message || error.statusText || error.status,
      errors: navigationLink.errors.extract()
    })
  }
}

export const updateHeaderLinksDragging = value => {
  return {
    type: constants.UPDATE_HEADER_LINKS_DRAGGING,
    value
  }
}

export const updateHeaderLink = (key, value) => {
  return {
    type: constants.UPDATE_HEADER_LINK,
    key,
    value
  }
}

export const deleteHeaderLink = id => async dispatch => {
  dispatch({
    type: constants.DELETE_HEADER_LINK_REQUEST,
    id
  })

  const navigationLink = NavigationLink.new({ id })

  try {
    await navigationLink.destroy()

    dispatch({
      type: constants.DELETE_HEADER_LINK_SUCCESS,
      id
    })
  } catch (error) {
    dispatch({
      type: constants.DELETE_HEADER_LINK_FAILURE,
      id,
      errorMessage: error.statusText || error.status
    })
  }
}

export const resetHeaderLink = () => {
  return {
    type: constants.RESET_HEADER_LINK
  }
}

export const sortHeaderLinks = body => async dispatch => {
  const navigationLink = NavigationLink.new()

  try {
    await navigationLink.sort({
      data: {
        attributes: { navigation: body }
      }
    })

    dispatch({
      type: constants.SORT_HEADER_LINKS_SUCCESS
    })
  } catch (err) {
    dispatch({
      type: constants.SORT_HEADER_LINKS_FAILURE,
      errorMessage: err
    })
  }
}

export const updateHeaderLinkError = (key, value) => {
  return {
    type: constants.UPDATE_HEADER_LINK_ERROR,
    key,
    value
  }
}
